<template>
  <div id="counter-down"  class="mb-1">
    <h1 id="headline">Przedsprzedaż kursów kończy się za...</h1>
    <div id="countdown">
      <ul>
        <li><span id="days" />Dni</li>
        <li><span id="hours" />Godzin</li>
        <li><span id="minutes" />Minut</li>
        <li><span id="seconds" />Sekund</li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CounterDown',
  components: {

  },
  data() {
    return {
      reviews: [],
      counters: null,
    }
  },
  mounted() {
    const second = 1000
    const minute = second * 60
    const hour = minute * 60
    const day = hour * 24

    // I'm adding this section so I don't have to keep updating this pen every year :-)
    // remove this if you don't need it
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()

    today = `${mm}/${dd}/${yyyy}`

    const countDown = new Date('09/15/2024 23:59:59').getTime()
    this.counters = setInterval(() => {
      const now = new Date().getTime()
      const distance = countDown - now

      document.getElementById('days').innerText = Math.floor(distance / (day)),
      document.getElementById('hours').innerText = Math.floor((distance % (day)) / (hour)),
      document.getElementById('minutes').innerText = Math.floor((distance % (hour)) / (minute)),
      document.getElementById('seconds').innerText = Math.floor((distance % (minute)) / second)

      // seconds
    }, 1000)
  },
  onUnmounted() {
    clearInterval(this.counters);
  },
}
</script>
<style lang="scss" scoped>
  #counter-down{
    background: #ffffff;
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5em;
    color: #3F3A64;
    text-align: center;
    h1{
      color: #20ad95;
    }
    ul{
      padding: 0;
    }
    li {
      display: inline-block;
      font-size: 1.5em;
      list-style-type: none;
      padding: 1em;
      text-transform: uppercase;
    }

    li span {
      display: block;
      font-size: 4.5rem;
      margin-bottom: 14px;
      color: #20ad95;
    }
    @media all and (max-width: 768px) {
      h1{
        font-size: 26px;
      }
      li {
        font-size: 12px;
      }

      li span {
        font-size: 22px;
        margin-bottom: 0;
      }
    }
  }
</style>
