<template>
  <div style="height: inherit">
    <div
      v-if="showBanner"
      class="mb-1"
    >
      <a :href="bannerShopLink">
        <img
            :src="bannerShop"
            alt="ChemMaster 2023"
            class="banner"
            width="100%"
        >
        <img
            :src="bannerShopMobile"
            alt="ChemMaster 2023"
            class="bannerMobile"
            width="100%"
        >
      </a>
    </div>

    <div
      v-if="showBannerDescription"
      class="banner-konsultacja mb-1"
      v-html="shopBannerDescription"
    />

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="custom-filters mt-1">
      <b-row>
        <b-col cols="12">
          <div class="d-inline-block mr-1 mt-1">
            <b-badge
              pill
              :variant="isActiveCategory('') ? 'primary' : 'light'"
              class="clickable"
              @click="clickOnCategory('')"
            >
              Wszystkie
            </b-badge>
          </div>
          <div
            v-for="category in categories"
            :key="category"
            class="d-inline-block mr-1"
          >
            <b-badge
              pill
              class="clickable"
              :variant="isActiveCategory(category) ? 'primary' : 'light'"
              @click="clickOnCategory(category)"
            >
              {{ category }}
            </b-badge>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <div class="row">
      <div class="col-lg-9">
        <section>
          <div
              v-for="(pcategory, indexC) in this.productsByCategories"
              :key="pcategory.id"
              :ref="getRef(indexC)"
          >
            <div
                class="mt-3 mb-2 d-flex flex-column justify-content-center"
                v-if="(selected_filter === '' || checkCategoryP(pcategory, selected_filter))"
            >
              <h3>{{pcategory.name}}</h3>
              <div v-html="pcategory.description"></div>
            </div>
            <div :class="[itemView]">
              <div
                v-for="(product, index) in pcategory.products"
                :key="product.id"
                :ref="getRef(index)"
              >
                <b-card
                  v-show="selected_groups.length === 0 && (selected_filter === '' || checkCategory(product, selected_filter))"
                  class="ecommerce-card position-relative"
                  :class="[product.grayed ? 'grayed' : '']"
                  no-body
                >
                  <img
                      :src="discountStickerImg2"
                      alt="discount-sticker"
                      class="discount-sticker"
                      v-if="product.price > 0 && product.categories[0].id === 6 && product.id !== 124"
                  >
                  <img
                    v-if="product.type === 'course' && product.price == 0 && !product.grayed"
                    :src="freeStickerImg"
                    alt="free-sticker"
                    class="free-sticker"
                  >
                  <div class="item-img text-center">
                    <b-link
                      v-if="product.type === 'course'"
                      :to="{ name: 'shop-item-details', params: {slug: product.slug, index: index }}"
                    >
                      <b-img
                        :alt="`${product.name}-${product.id}`"
                        fluid
                        class="card-img-top"
                        :src="`${product.thumbnail}`"
                        @click="clickOnProduct(product.id, index)"
                      />
                    </b-link>
                    <b-link
                      v-if="product.type === 'group'"
                      @click="toggleGroup(product)"
                    >
                      <b-img
                        v-if="product.type === 'group'"
                        :alt="`${product.name}-${product.id}`"
                        fluid
                        class="card-img-top"
                        :src="`${product.thumbnail}`"
                      />
                    </b-link>
                  </div>

                  <!-- Product Details -->
                  <b-card-body>
                    <div class="item-wrapper">
                      <div>
                        <h3>{{ product.title }}</h3>
                        <!-- price info -->
                        <div v-if="!product.subscription">
                          <div class=" mt-1">
                            <h4
                              v-if="product.type === 'course'"
                              class="item-price d-inline"
                            >
                              {{ product.price.toFixed(2) }} zł
                            </h4>
                            <h6
                              v-if="product.sale_price && product.type === 'course'"
                              class="item-price-sale d-inline"
                            >
                              {{ product.sale_price.toFixed(2) }} zł
                            </h6>
                          </div>
                          <h6
                            v-if="product.sale_price && product.type === 'course'  && !product.grayed"
                            class="lowest-price-text"
                          >
                            Najniższa cena z ostatnich 30dni: {{ product.lowest_price.toFixed(2) }} zł
                          </h6>
                        </div>
                        <div v-else-if="product.subscription">
                          <h4 class="text-success">
                            Subskrypcja
                          </h4>
                        </div>
                      </div>
                    </div>
                  </b-card-body>

                  <!-- Product Actions -->
                  <div class="item-options text-center">
                    <div class="item-wrapper">
                      <div class="item-cost">
                        <h4
                          v-if="product.type === 'course' && !product.subscription"
                          class="item-price"
                        >
                          {{ product.price }} zł
                        </h4>
                      </div>
                    </div>
                    <b-link
                      v-if="product.type === 'course' && !product.subscription"
                      class="flex-fill"
                      style="line-height: 39px"
                      :to="{ name: 'shop-item-details', params: {slug: product.slug, index: index }}"
                    >
                      Zobacz więcej
                    </b-link>
                    <b-button
                      v-if="product.type === 'course' && !product.subscription && !product.grayed"
                      variant="primary"
                      tag="a"
                      class="btn-cart flex-fill"
                      @click="clickOnAddToCart(product)"
                    >
                      <feather-icon
                        icon="ShoppingCartIcon"
                        class="mr-50"
                      />
                      <span>{{ product.isInCart ? 'Zobacz koszyk' : 'Kup teraz' }}</span>
                    </b-button>
                    <b-button
                      v-if="product.type === 'group'"
                      variant="primary"
                      tag="a"
                      class="btn-cart"
                      @click="toggleGroup(product)"
                    >
                      <feather-icon
                        icon="Maximize2Icon"
                        class="mr-50"
                      />
                      <span>Pokaż kursy</span>
                    </b-button>
                  </div>
                </b-card>
                <div
                  v-if="product.type === 'group'"
                  class="mt-2"
                >
                  <div v-show="selected_groups.includes(product)" class="group-products-container">
                    <b-button
                      variant="primary"
                      tag="a"
                      @click="toggleGroup(product)"
                    >
                      <feather-icon
                        icon="ArrowLeftIcon"
                      />
                      <span>Wróć</span>
                    </b-button>
                  </div>
                  <div class="grid-view">
                    <b-card
                      v-for="child in product.children"
                      v-show="selected_groups.includes(product)"
                      :key="child.id"
                      class="ecommerce-card position-relative"
                      :class="[child.grayed ? 'grayed' : '']"
                      no-body
                    >
                      <img
                        v-if="showAlert()"
                        :src="discountStickerImg"
                        alt="discount-sticker"
                        class="discount-sticker"
                      >
                      <img
                        v-if="child.type === 'course' && child.price == 0 && !child.grayed"
                        :src="freeStickerImg"
                        alt="free-sticker"
                        class="free-sticker"
                      >
                      <div class="item-img text-center">
                        <b-link :to="{ name: 'shop-item-details', params: { slug: child.slug }}">
                          <b-img
                            :alt="`${child.name}-${child.id}`"
                            fluid
                            class="card-img-top"
                            :src="`${child.thumbnail}`"
                          />
                        </b-link>
                      </div>

                      <!-- Product Details -->
                      <b-card-body>
                        <div class="item-wrapper">
                          <div>
                            <h3>{{ child.title }}</h3>
                            <h4 class="item-price mt-1"  v-if="!child.grayed">
                              {{ child.price.toFixed(2) }} zł
                            </h4>
                            <h6
                              v-if="child.sale_price && !child.grayed"
                              class="item-price-sale"
                            >
                              {{ child.sale_price.toFixed(2) }} zł
                            </h6>
                          </div>
                        </div>
                      </b-card-body>

                      <!-- Product Actions -->
                      <div class="item-options text-center">
                        <div class="item-wrapper">
                          <div class="item-cost">
                            <h4 class="item-price">
                              {{ child.price }} zł
                            </h4>
                          </div>
                        </div>
                        <b-button
                          variant="primary"
                          tag="a"
                          class="btn-cart"
                          v-if="!child.grayed"
                          @click="clickOnAddToCart(child)"
                        >
                          <feather-icon
                            icon="ShoppingCartIcon"
                            class="mr-50"
                          />
                          <span>{{ child.isInCart ? 'Zobacz koszyk' : 'Dodaj do koszyka' }}</span>
                        </b-button>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- Pagination -->
        <section>
          <b-row>
            <b-col cols="12">
              <b-pagination
                v-model="filters.page"
                :total-rows="totalProducts"
                :per-page="filters.perPage"
                first-number
                align="center"
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </section>
      </div>
      <div class="col-lg-3">
        <b-link
          :to="{ name: 'shop-item-details', params: {slug: 'bon-podarunkowy'}}"
          class="d-inline-block"
          style="margin-top: 2rem;"
        >
          <b-img
            alt="Bon podarunkowy"
            style="max-width: 100%;"
            :src="voucherImage"
          />
        </b-link>
        <reviews />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BFormRadio,
  BFormRadioGroup,
  BImg,
  BLink,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import reviews from '@/views/Reviews.vue'
import CounterDown from '@/views/shop/CounterDown.vue'
import { useShopFiltersSortingAndPagination, useShopRemoteData, useShopUi } from './useECommerceShop'
import { useEcommerceUi } from './useEcommerce'

export default {
  directives: {
    Ripple,
  },
  components: {
    reviews,
    // BSV
    BRow,
    BCol,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BPagination,
    BBadge,
    CounterDown,
  },
  data() {
    return {
      blackFridayImg: require('@/assets/images/chemmaster/BF/banner-shop.png'),
      blackFridayMobileImg: require('@/assets/images/chemmaster/BF/banner-shop-mobile.png'),
      discountStickerImg: require('../../assets/images/chemmaster/sticker.png'),
      discountStickerImg2: require('../../assets/images/chemmaster/BF/wakacyjne-100.png'),
      freeStickerImg: require('@/assets/images/chemmaster/darmowe-1.png'),
      voucherImage: require('@/assets/images/chemmaster/voucher.png'),
      selected_groups: [],
      selected_filter: '',
      filtered_products: [],
      showBanner: false,
      bannerShop: '',
      bannerShopLink: '',
      bannerShopMobile: '',
      showBannerDescription: false,
      shopBannerDescription: '',
    }
  },
  computed: {
    categories() {
      if (typeof this.products === 'undefined') {
        return []
      }
      const categories = []

      this.products.forEach(product => {
        product.categories.forEach(category => {
          if (!categories.includes(category.name)) {
            categories.push(category.name)
          }
        })
      })

      return categories
    },
    productsByCategories() {
      if (typeof this.products === 'undefined') {
        return []
      }
      const categoriesWithProducts = {}

      this.products.forEach(product => {
        product.categories.forEach(category => {

          if (categoriesWithProducts[category.order] === undefined) {
            categoriesWithProducts[category.order] = {
              name: category.name,
              description: category.description,
              order: category.order,
              products: [],
            }
          }
          categoriesWithProducts[category.order].products.push(product)
        })
      })

      console.log(categoriesWithProducts);

      return categoriesWithProducts
    },
  },
  mounted() {
    this.settings()

    watch(
      () => this.products,
      this.filterProducts,
    )
    watch(
      () => this.selected_filter,
      this.filterProducts,
    )
    watch(
      () => this.products,
      this.dataLayerPushDataAboutProducts,
    )
  },
  methods: {
    checkCategory(product, category) {
      const categories = product.categories.filter(item => item.name === category)
      return categories.length > 0
    },
    checkCategoryP(pCategory, category) {
      console.log(pCategory.name === category)
      return (pCategory.name === category)
    },
    settings() {
      this.$http.get(`${this.$store.state.apiDomain}/api/settings`).then(response => {
        if (response.data.status === 'ok') {
          this.showBanner = response.data.showBanner
          this.bannerShop = response.data.shopBannerUrl
          this.bannerShopMobile = response.data.shopBannerMobileUrl
          this.bannerShopLink = response.data.shopBannerLink
          this.showBannerDescription = response.data.showBannerDescription
          this.shopBannerDescription = response.data.shopBannerDescription
        }
      })
    },
    showCounterDown() {
      const timeInMs = Date.now()
      const timeToEnable = 1671404400000 // 19.12.2022 00:00 CET
      //   var timeToEnable = 1671318000000; // 19.12.2022 00:00 CET
      const timeToDisable = 1725832800000 // 25.12.2022 23:59 CET

      if (timeInMs >= timeToEnable && timeInMs <= timeToDisable) {
        return true
      }

      return false
    },
    showAlert() {
      const timeInMs = Date.now()
      const timeToEnable = 1671404400000 // 19.12.2022 00:00 CET
      //   var timeToEnable = 1671318000000; // 19.12.2022 00:00 CET
      const timeToDisable = 1691971199000 // 25.12.2022 23:59 CET

      if (timeInMs >= timeToEnable && timeInMs <= timeToDisable) {
        return true
      }

      return false
    },
    toggleGroup(product) {
      if (this.selected_groups.includes(product)) {
        this.selected_groups = this.selected_groups.filter(
          item => item !== product,
        )
      } else {
        this.selected_groups.push(product)
        setTimeout(() => {
          const el = document.querySelector('.group-products-container')
          el.scrollIntoView()
        }, 300)
      }
    },
    isActiveCategory(category) {
      return this.selected_filter === category
    },
    filterProducts() {
      if (this.selected_filter !== '') {
        this.filtered_products = this.products.filter(product => {
          const categories = product.categories.filter(item => item.name === this.selected_filter)
          return categories.length > 0
        })
      } else {
        this.filtered_products = this.products
      }

     // this.productsByCategories
    },
    getRef(index) {
      return `product_${index}`
    },
    clickOnProduct(productId, index) {
      const selectedProduct = this.products.filter(item => item.id === productId)[0]
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: [{
            item_name: selectedProduct.title,
            item_id: selectedProduct.id,
            price: selectedProduct.price,
            item_category: selectedProduct.category,
            item_list_name: `Kategoria: ${selectedProduct.category}`,
            item_list_id: `kategoria-${selectedProduct.category.toLowerCase()}`,
            index: index + 1,
            quantity: 1,
          }],
        },
      })
    },
    clickOnCategory(category) {
      this.selected_filter = category
      let selectedProducts = []
      if (category !== '') {
        // eslint-disable-next-line array-callback-return
        selectedProducts = this.products.filter(product => {
          const categories = product.categories.filter(item => item.name === category)
          return categories.length > 0
        })
      } else {
        selectedProducts = this.products
      }

      this.dataLayerPushDataAboutProducts(selectedProducts, category)
    },
    clickOnAddToCart(product) {
      this.handleCartActionClick(product)
      if (!product.isInCart) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'PLN',
            value: product.price,
            items: [{
              item_name: product.title,
              item_id: product.id,
              price: product.price,
              item_category: product.categories[0].name,
              item_list_name: `Kategoria: ${product.categories[0].name}`,
              item_list_id: `kategoria-${product.categories[0].name.toLowerCase()}`,
              index: 1,
              quantity: 1,
            }],
          },
        })
      }
    },
    dataLayerPushDataAboutProducts(products, category) {
      const selectedProducts = products ?? this.products
      const selectedCategory = category === '' ? 'Wszystkie' : category

      const items = selectedProducts.map((product, index) => ({
        item_name: product.title,
        item_id: product.id,
        price: product.price,
        item_category: selectedCategory,
        item_list_name: `Kategoria: ${selectedCategory}`,
        item_list_id: `kategoria-${selectedCategory.toLowerCase()}`,
        index: index + 1,
        quantity: 1,
      }))
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
          items,
        },
      })
    },
  },
  setup() {
    const {
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView,
      itemViewOptions,
      totalProducts,
    } = useShopUi()

    const { products, fetchProducts, getCartToken } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const initializeCart = () => {
      getCartToken()
    }

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
        version: 2,
      })
        .then(response => {
          products.value = response.data.courses
          totalProducts.value = response.data.total

          const selectedProducts = products.value
          const selectedCategory = 'Wszystkie'

          const items = selectedProducts.map((product, index) => ({
            item_name: product.title,
            item_id: product.id,
            price: product.price,
            item_category: product.categories[0].name,
            item_list_name: `Kategoria: ${selectedCategory}`,
            item_list_id: `kategoria-${selectedCategory.toLowerCase()}`,
            index: index + 1,
            quantity: 1,
          }))
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'view_item_list',
            ecommerce: {
              items,
            },
          })
        })
    }

    initializeCart()
    fetchShopProducts()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.card.grayed{
  filter: grayscale(1);
}
.free-sticker {
  width: 45%;
  position: absolute;
  right: -10%;
}
.clickable{
  cursor: pointer;
}
.custom-filters .badge{
  font-size: 16px;
  padding: 10px 15px;
  @media screen and (max-width: 768px) {
    margin-bottom: 7px;
  }
  &.badge-light{
    color: #169179;
    background-color: transparent;
  }
}
.chemmystery-banner{
  max-width: 100%;
}
.discount-sticker {
  width: 40%;
  position: absolute;
  right: 0;
}
@media all and (max-width: 768px) {
  .custom-filters .badge{
    font-size: 14px;
    padding: 7px 12px;
  }
  .banner{
    display: none;
  }
}
@media all and (min-width: 769px) {
  .bannerMobile{
    display: none;
  }
}

.ecommerce-card{
  box-shadow: none;
  .item-wrapper{
    h3{
      font-size: 20px;
      font-weight: 600;
      line-height: 1.34;
      color: #333333;
    }
  }
}
.ecommerce-application .grid-view .ecommerce-card .item-img{
  padding-top: 0 !important;
}
.banner-konsultacja{
  background: #ffffff;
  padding: 15px 25px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
  color: #3F3A64;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
}

</style>
